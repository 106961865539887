<template>
  <div class="text-center text-primary">
    <b-spinner />
    <div class="mt-1">
      {{ message }}
    </div>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  name: 'PreLoading',
  components: {
    BSpinner,
  },
  props: {
    message: {
      type: String,
      default: 'Lütfen Bekleyiniz..',
    },
  },
}
</script>

<style scoped>

</style>
